<template>
  <v-container grid-list-xl>
    <v-layout wrap justify-space-between>
      <v-flex xs12 md12>
        <v-alert :value="alert" type="success">Başarıyla kaldedildi.</v-alert>
      </v-flex>
      <v-flex xs3 md3>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="startDate"
              label="Start Date"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="tr-TR"
            v-model="startDate"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs3 md3>
        <v-menu
          v-model="menu3"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="endDate"
              label="End Date"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="tr-TR"
            v-model="endDate"
            @input="menu3 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs3 md3>
        <v-text-field
          v-model="product.goalPrice"
          label="GoalPrice"
          :counter="max"
          required
        ></v-text-field>
      </v-flex>
      <v-flex xs3 md3>
        <v-text-field
          v-model="product.quarter"
          label="Quarter"
          type="number"
          :counter="max"
          required
        ></v-text-field>
      </v-flex>

      <v-btn color="success" @click="save">Save</v-btn>
    </v-layout>
  </v-container>
</template>
<script>
import router from "@/router";

export default {
  data: () => ({
    product: {},
    alert: false,
    menu2: false,
    menu3: false,
    menu4: false,
    startDate: null,
    endDate: null,
    max: 120,
  }),

  methods: {
    async save() {
      // this.product.c = parseInt(this.product.c || 0);
      // // this.product.p = this.product.p.replace(',',".");
      // this.product.s = parseInt(this.product.s || 0);
      // this.product.g = parseInt(this.product.g || 0);
      // this.product.t = this.productTypeSelect.key;
      this.product.startDate = new Date(this.startDate).getTime() / 1000;
      this.product.endDate = new Date(this.endDate).getTime() / 1000;
      await this.$store.dispatch("salesTargets/createOrUpdate", this.product);
      router.push("/salesTargets");
    },
  },
  validateField() {
    this.$refs.form.validate();
  },
  computed: {},
  async created() {
    this.product = {};

    if (this.$route.params.id) {
      await this.$store.dispatch("salesTargets/get", this.$route.params.id);
      this.product = this.$store.state.salesTargets.detail;
      this.startDate = new Date(this.product.startDate * 1000)
        .toISOString()
        .substr(0, 10);
      this.endDate = new Date(this.product.endDate * 1000)
        .toISOString()
        .substr(0, 10);
    }
  },
};
</script>
